import { faCertificate, faBookmark, faCircle, faSquare, faTag } from '@fortawesome/free-solid-svg-icons'
import * as Device from 'expo-device';
import { Platform, Text, View } from 'react-native';
import { MainColor } from './appColors';
import IcPostNew from '../assets/svg/ic_post_newness';
import IcPostPromo from '../assets/svg/ic_post_promo';
import IcPostBest from '../assets/svg/ic_post_best';


export const Fonts = { Light: 'Livvic-Light', Regular: 'Livvic-Regular', Medium: 'Livvic-Medium', SemiBold: 'Livvic-SemiBold', Bold: 'Livvic-Bold' }
export const AttachmentType = {Article:1, Image:2, Pdf:3, Shop:4}

export const PostCategoryType = {Promo:1, Novelty:2, Advert:3, NewShop:4, SpotlightShop:5,BestSeller:6, Voucher:7}
export const ArticleCategoryType = {Promo:1, Novelty:2, BestSeller:3}
export const PostLayoutType = { Auto: 1, Horizontal: 2, Vertical: 3 }


export async function getInfoDevice(){
  return {
      "Plateform":Platform.OS + ' '+Device.osVersion,
      "Type": await Device.getDeviceTypeAsync(),
      "Manufacturer":Device.manufacturer,
      "Model":Device.modelName,
  }
}

export function getBadgeIconFromName(name){
  switch(name){
      case 'certificate': return faCertificate;
      case 'bookmark': return faBookmark;
      case 'circle': return faCircle;
      case 'square': return faSquare;
      case 'tag': return faTag;
  }
}

export function getIconArticleType(ArticleCategory){
  switch(ArticleCategory){
    case ArticleCategoryType.Promo: return <IcPostPromo/>;
    case ArticleCategoryType.Novelty: return <IcPostNew/>;
    case ArticleCategoryType.BestSeller: return <IcPostBest/>;
  }
}

export function getArticleCategoryFromPostCategory(postCategory){
  switch(postCategory){
      case PostCategoryType.BestSeller:
          return ArticleCategoryType.BestSeller;
      case PostCategoryType.Novelty:
          return ArticleCategoryType.Novelty;
      case PostCategoryType.Promo:
          return ArticleCategoryType.Promo;
  }
}

export function getDayNameOfWeek(translateManager){
  return [
    translateManager.translate("ShopEditOpeningTime.Monday"),
    translateManager.translate("ShopEditOpeningTime.Tuesday"),
    translateManager.translate("ShopEditOpeningTime.Wednesday"),
    translateManager.translate("ShopEditOpeningTime.Thursday"),
    translateManager.translate("ShopEditOpeningTime.Friday"),
    translateManager.translate("ShopEditOpeningTime.Saturday"),
    translateManager.translate("ShopEditOpeningTime.Sunday"),
  ];
}


export function getMIMETypeFile(extension){
  extension = extension.toLowerCase();
  if(extension.indexOf('.jpeg') !== -1 || extension.indexOf('.jpg')!== -1)
    return 'image/jpeg';
  if(extension.indexOf('pdf') !== -1)
    return 'application/pdf';
  else
    return 'image/png';
}

export function getPriceRender(article) {
  if (article.Promo == undefined || article.Promo.Badge == null) return <Text>{article.Price.toFixed(2)}€</Text>;

  return (
    <View style={{ flexDirection: 'row' }}>
      <Text style={{ color: MainColor, fontWeight: 'bold',fontFamily:Fonts.Bold }}>{article.Promo.NewPrice.toFixed(2)}€</Text>
      <Text style={{ textDecorationLine: 'line-through', marginLeft: 5,fontFamily:Fonts.Regular }}>{article.Price.toFixed(2)}€</Text>
    </View>
  )
}






