import React, { Component } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { Fonts, PostCategoryType } from '../utils/utils';
import CstLogo from './CstLogo';
import IcPostNew from '../assets/svg/ic_post_newness';
import IcPostPromo from '../assets/svg/ic_post_promo';
import IcPostBest from '../assets/svg/ic_post_best';
import IcPostAd from '../assets/svg/ic_post_ad';

class PostItemHeader extends Component {

  _onPressHeader(appCmCstId){
    if(this.props.onPressHeader)
      return this.props.onPressHeader(appCmCstId);
  }

  _getIconPostType(postCategory){
    switch(postCategory){
      case PostCategoryType.Promo: return <IcPostPromo size='6vmin'/>;
      case PostCategoryType.Novelty: return <IcPostNew size='6vmin'/>;
      case PostCategoryType.Advert: return <IcPostAd size='6vmin'/>;
      case PostCategoryType.BestSeller: return <IcPostBest size='6vmin'/>;
    }
  }

  _getHeaderPost(post){
    return(
      <View style={{flexDirection:'row', alignItems:'center'}}>
        <TouchableOpacity onPress={()=> {this._onPressHeader(post.AppCmCstId)}} style={{flex:1, flexDirection:'row'}}>

          <CstLogo logo={post.ShopLogo} name={post.ShopName} width='8vmin' height='8vmin' borderRadius='4vmin'/>

          <View style={{flex:1,paddingLeft:5}}>
            <View style={{flexDirection:'row', alignItems:'center',flex:1}}>
              <Text style={{fontSize:'2vmin', fontFamily:Fonts.Bold}}>{post.ShopName}</Text>
            </View>
            <View style={{flex:1,flexDirection:'row'}}>
              <Text style={{fontSize:'1.9vmin', color:'#A0A0A0', fontFamily:Fonts.Light}}>{post.ShopDomaine} {(post.ShopCity?.length>0?'-':'')} {post.ShopCity}</Text>
            </View>

          </View>
        </TouchableOpacity>
        {this._getIconPostType(post.Category)}
      </View>   
    );
  }

  render() {
    const {post} = this.props
    return this._getHeaderPost(post);
  }
}


export default PostItemHeader;

