import React, { Component } from 'react';
import { View, Text, Dimensions, ScrollView } from 'react-native';
import { getPostDetails } from '../services/consumerApi';
import { AttachmentType, Fonts, PostCategoryType } from '../utils/utils';
import Error from '../views/Error';
import LoadingIndicator from './LoadingIndicator';
import PostCarouselLayout from './PostCarouselLayout';
import PostImageLayout from './PostImageLayout';
import PostItemHeader from './PostItemHeader';
import StoreFooter from './StoreFooter';
import ViewMoreText from 'react-native-view-more-text';
import { SecondaryColor } from '../utils/appColors';
import { connect } from 'react-redux';


class PostItem extends Component {
  constructor(props) {
    super(props);
    this.updateLayout = true;
    this.state = {
      isLoading: true,
      post: undefined,
      widthScreen: Dimensions.get('window').width,
      height: Dimensions.get('window').height
    };
  }

  translate(key) {
    return this.props.translateManager.translate(key);
  }

  componentDidMount() {
    if (this.props.post) {
      getPostDetails(this.props.post).then((response) => {
        this.setState({ post: response.Data, isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate() {
    let width = Dimensions.get('window').width;
    if (this.state.widthScreen != width)
      this.setState({ widthScreen: width })
  }

  _getPostAttachment(post) {
    var attachments = post.Attachments;
    var articlesId = attachments.filter(x => x.Type == AttachmentType.Article).map(x => x.Content.AppCmArticleId);

    if (attachments.length == 1 && attachments[0].Type == AttachmentType.Article) {
      var article = attachments[0].Content;
      return article.Images.map((item, index) => {
        return {
          uri: item.ImageName,
          onPress: () => { this.props.navigation.navigate('Article', { ids: articlesId, shopName: post.ShopName, shopLogo: post.ShopLogo }) }
        }
      })
    }

    return attachments.map((item, index) => {
      switch (item.Type) {
        case AttachmentType.Image:
          return {
            uri: item.Content,
            onPress: () => this.props.showModalImages(attachments.filter(x => x.Type == AttachmentType.Image).map(x => x.Content), index)
          }
        case AttachmentType.Article:
          return {
            uri: item.Content.Images[0].ImageName,
            article: item.Content,
            onPress: () => { this.props.navigation.navigate('Article', { ids: articlesId.sort((a, b) => (a === item.Content.AppCmArticleId) ? -1 : 0), shopName: post.ShopName, shopLogo: post.ShopLogo }) }
          }
        case AttachmentType.Pdf:
          return {
            uri: item.Thumbnail,
            onPress: () => { window.open('https://docs.google.com/gview?embedded=true&url=' + item.Content, '_blank'); }
          }
      }
      return {}
    });
  }

  _renderMessage(post) {
    if (post.Message == undefined || post.Message.length == 0)
      return null;
    return (
      <View style={{ paddingVertical: 10, paddingLeft: '2vmin' }}>
        <ViewMoreText
          numberOfLines={5}
          renderViewMore={(onPress) => <Text style={{ color: SecondaryColor }} onPress={onPress}>{this.translate("ViewMore")}</Text>}
          renderViewLess={(onPress) => <Text style={{ color: SecondaryColor }} onPress={onPress}>{this.translate("ViewLess")}</Text>}
          textStyle={{fontFamily:Fonts.Regular}}
        >
          {post.Message}
        </ViewMoreText>
      </View>);
  }

  _renderContentPromo(post) {
    let att = this._getPostAttachment(post);
    return <View style={{ height: this.state.height }}> <PostCarouselLayout postAttachements={att} /></View>
  }

  _renderLayoutImages(post) {
    if (post.Attachments != undefined && post.Attachments.length > 0) {
      let badge = undefined;
      if (post.Category == PostCategoryType.Promo)
        badge = post.Attachments[0].Content.Promo.Badge;
      return (<View style={{ height: this.state.height }}> <PostImageLayout style={{ flex: 1 }} images={this._getPostAttachment(post)} badge={badge} width='80%' height='80%' layoutType={post.LayoutTypeId} /> </View>);
    }
    return (<View />);
  }

  _renderContent(post) {
    if (post.Category == PostCategoryType.Promo && post.Attachments.length > 1)
      return this._renderContentPromo(post);
    else
      return this._renderLayoutImages(post);
  }

  _onLayout = (event) => {
    let layout = event.nativeEvent.layout;
    if (this.updateLayout) {
      this.setState({ height: (layout.height / 2), offsetX: layout.x })
      this.updateLayout = false
    }
  }

  _onPressHeader = () => {
    this.props.navigation.navigate('CstPage', { appCmCstId: this.state.post.AppCmCstId });
  }

  render() {
    if (this.state.isLoading)
      return <LoadingIndicator />

    if (!this.state.post)
      return <Error />
    return (
      <ScrollView style={{ backgroundColor: '#e2e2e2' }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={{ flex: this.state.widthScreen > 1000 ? .7 : 0, backgroundColor: '#e2e2e2', }} />
          <View style={{ flex: 1, marginVertical: 10 }}>
            <View onLayout={this._onLayout} style={{ backgroundColor: '#ffffff', padding: '2vmin', paddingBottom: '1vmin', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
              <PostItemHeader post={this.state.post} onPressHeader={this._onPressHeader} />
              {this._renderMessage(this.state.post)}
              {this._renderContent(this.state.post)}
            </View>
            <StoreFooter />
          </View>
          <View style={{ flex: this.state.widthScreen > 1000 ? .7 : 0, backgroundColor: '#e2e2e2' }} />
        </View>
      </ScrollView>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    translateManager: state.translateManager
  }
}

export default connect(mapStateToProps)(PostItem);;
