

export default class translateManager {
  constructor(langId) {
    switch (langId) {
      case 2:
        this.langId=2;
        this.dic = require('./en.json');
        break;
      default:
        this.langId=1;
        this.dic = require('./fr.json');
        break;
    }
  }

  translate(key) {
    var translation = this.dic[key];
    if (translation == undefined)
      return 'misssing translation for [' + key + ']';
    return translation;
  }
}






