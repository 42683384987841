import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Component } from 'react'
import { View, Image, TouchableOpacity, Text } from 'react-native'


export class ModalImage extends Component {

  constructor(props){
    super(props);
    this.state={
      currentIndex:this.props.index || 0
    }
  }

  _getPreviewImage=()=>{
    if(this.state.currentIndex>0)
      this.setState({currentIndex:--this.state.currentIndex});
    else
      this.setState({currentIndex:this.props.images.length-1});
  }

  _getNextImage=()=>{
    if(this.state.currentIndex<this.props.images.length-1)
      this.setState({currentIndex:++this.state.currentIndex});
    else
      this.setState({currentIndex:0});
  }

  _renderBtnPrev(){
    return(
      <TouchableOpacity onPress={this._getPreviewImage} style={{marginLeft:'2vw'}}>
        <FontAwesomeIcon icon={faArrowAltCircleLeft} size={30} color='#ffffff' />
      </TouchableOpacity>
    )
  }

  _renderBtnNext(){
    return(
      <TouchableOpacity onPress={this._getNextImage}  style={{marginRight:'2vw'}}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} size={30} color='#ffffff'/>
      </TouchableOpacity>
    )
  }

  render() {
    return (
      <View style={{position:'absolute', height:'100%', width:'100%', backgroundColor:'#00000099', flexDirection:'row', alignItems:'center'}}>

        {this._renderBtnPrev()}

        <View style={{flex:1,height:'80%'}}>
          <Image source={{uri:this.props.images[this.state.currentIndex]}} style={{height:'100%', aspectRatio:1}} resizeMode='contain'/>
          <Text style={{textAlign:'center', color:'#ffffff', fontSize:'2vh'}}> {this.state.currentIndex+1} / {this.props.images.length}</Text>
        </View>

        
        {this._renderBtnNext()}
        
        <TouchableOpacity onPress={this.props.onClose}  style={{position:'absolute',right:10, top:10}}>
          <FontAwesomeIcon icon={faTimesCircle} color='#ffffff' size={30}/>
        </TouchableOpacity>
    </View>
    )
  }
}

export default ModalImage
