import React, { Component } from 'react';
import moment from 'moment';
import { View, Text, TouchableOpacity, Image, ScrollView } from 'react-native';
import { LightGreyColor } from '../utils/appColors';
import { getBadgeIconFromName, getPriceRender } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

class PostCarouselLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  shouldComponentUpdate(nextProps, nextState) {
    let prevId = this.props.postAttachements.map((item) => item.article.AppCmArticleId);
    let nextId = nextProps.postAttachements.map((item) => item.article.AppCmArticleId);
    if (JSON.stringify(prevId) == JSON.stringify(nextId))
      return false;
    return true;
  }

  _drawBadge(article) {
    if (article.Promo == null || article.Promo.Badge == null) return null;

    if (article.Promo.Badge.Icon == null)
      article.Promo.Badge.Icon = getBadgeIconFromName(article.Promo.Badge.IconName);


    return (
      <View style={{ position: 'absolute', right: 10, top: 25 }}>
        <FontAwesomeIcon icon={article.Promo.Badge.Icon} color={article.Promo.Badge.Color} size='10vmin' />
        <View style={{ position: 'absolute', justifyContent: 'center', width: '10vmin', height: '10vmin', backgroundColor: '#ffffff00' }}>
          <Text style={{
            textAlign: 'center',
            fontSize: '3vmin',
            color: article.Promo.Badge.TextColor
          }}>
            {article.Promo.Badge.Text}
          </Text>
        </View>
      </View>
    )
  }

  _renderPromoDate(promo) {
    if (!promo || !promo.ValidityDate)
      return null;
    let date = promo.ValidityDate;
    return (
      <Text style={{ fontWeight: 'bold', fontSize: 12, color: '#A0A0A0' }}>Valable jusqu'au {moment(date).format("DD/MM")}</Text>
    )
  }


  _renderItem = ({ item, index }) => {
    const article = item.article;
    if (!article)
      return null;

    return (
      <TouchableOpacity style={{ borderColor: '#e6e6e6', borderWidth: 1, borderRadius: 5, margin: 8, width: '400px' }} onPress={item.onPress} activeOpacity={.8} key={index}>

        <Image source={{ uri: item.uri }} style={{ flex: 1, borderColor: '#b2b2b2', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} resizeMethod='scale' />

        {this._drawBadge(article)}

        <View style={{ flexDirection: 'row', backgroundColor: LightGreyColor, padding: 5 }}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: 'bold' }}>{article.Name}</Text>
            <Text>{getPriceRender(article)}</Text>
            <Text style={{ flex: 1, textAlignVertical: 'bottom' }}>{this._renderPromoDate(article.Promo)}</Text>
          </View>
          <TouchableOpacity  style={{ backgroundColor:'#fff', borderColor: '#e6e6e6', borderWidth: 1, padding: 5, alignSelf:'center' }} onPress={item.onPress}>
            <Text style={{color:'#4c5770', fontWeight:'bold'}}>Détails</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  }

  render() {
    if (!this.props.postAttachements || this.props.postAttachements.length <= 0)
      return null;
    return (
      <ScrollView
        horizontal>
        {this.props.postAttachements.map((item, index) => this._renderItem({ item, index }))}
      </ScrollView>
    );
  }
}

export default PostCarouselLayout;
