import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Component } from 'react';
import { View, Text, TouchableOpacity, FlatList, Image } from 'react-native';
import { Fonts, getBadgeIconFromName, getPriceRender } from '../utils/utils';
import StoreFooter from './StoreFooter';

class ArticleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0
    };
  }

  _renderImageItem(image, index) {
    return (
      <TouchableOpacity onPress={() => this.setState({ currentImageIndex: index })}>
        <Image source={{ uri: image }} style={{ width: '15vmin', height: '15vmin', aspectRatio: 1, resizeMode: 'contain' }} />
      </TouchableOpacity>
    )
  }

  _drawBadge(article) {
    if (article.Promo == null || article.Promo.Badge == null) return null;

    if (article.Promo.Badge.Icon == null)
      article.Promo.Badge.Icon = getBadgeIconFromName(article.Promo.Badge.IconName);


    return (
      <View style={{ position: 'absolute', right: 10, top: 25 }}>
        <FontAwesomeIcon icon={article.Promo.Badge.Icon} color={article.Promo.Badge.Color} size='10vmin' />
        <View style={{ position: 'absolute', justifyContent: 'center', width: '10vmin', height: '10vmin', backgroundColor: '#ffffff00' }}>
          <Text style={{
            textAlign: 'center',
            fontSize: '3vmin',
            color: article.Promo.Badge.TextColor
          }}>
            {article.Promo.Badge.Text}
          </Text>
        </View>
      </View>
    )
  }

  render() {
    const { article } = this.props;
    return (
      <View style={{ flex: 1, marginBottom: '1vmin' }}>
        <View style={{ alignItems: 'center', padding: '2vmin', backgroundColor: '#ffffff', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>

          <Text style={{ fontSize: 18, fontFamily:Fonts.Bold}}>{article.Name}</Text>
          <Text style={{ fontSize: 16, marginBottom: 5, fontFamily:Fonts.Regular }}>{article.Brand}</Text>
          {article.Images?.length > 0
            ? (
              <TouchableOpacity onPress={() => this.props.showModalImages(article.Images.map((item) => item.ImageName), this.state.currentImageIndex)} activeOpacity={.5}>
                <Image source={{ uri: article.Images[this.state.currentImageIndex].ImageName }}
                  style={{ width: '40vmin', height: '40vmin', aspectRatio: 1, resizeMode: 'contain' }} />

              </TouchableOpacity>
            )
            : null}


          {this._drawBadge(article)}

          {(article.Images?.length > 1)
            ? (
              <FlatList
                style={{ marginTop: 10, alignSelf: 'center', maxWidth: '100%' }}
                horizontal={true}
                data={article.Images}
                renderItem={({ item, index }) => this._renderImageItem(item.ImageName, index)}
                keyExtractor={(item, index) => index.toString()}
              />
            )
            : null}


          <View style={{ alignItems: 'left', width: '100%' }}>
            <Text style={{ fontSize: 16, fontFamily:Fonts.Bold, marginTop: 10 }}>Prix</Text>
            {getPriceRender(article)}

            <Text style={{ fontSize: 16, fontFamily:Fonts.Bold, marginTop: 10 }}>Description</Text>
            <Text style={{ fontSize: 16, paddingRight: 20,fontFamily:Fonts.Regular }}>{article.Description}</Text>
          </View>

        </View>
        <StoreFooter />
      </View>

    )
  }
}

export default ArticleItem;
