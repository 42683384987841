import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView, Dimensions } from 'react-native';
import StoreFooter from '../components/StoreFooter';
import RatingIndicator from '../components/RatingIndicator';
import ViewMoreText from 'react-native-view-more-text';
import { GetCstInfo } from '../services/consumerApi';
import LoadingIndicator from '../components/LoadingIndicator';
import CstLogo from '../components/CstLogo';
import PosArrowScrollview from '../components/PosArrowScrollview';
import { connect } from 'react-redux';
import { Fonts } from '../utils/utils';
import PosInfo from '../components/PosInfo';
import { MainColor, SecondaryColor } from '../utils/appColors';


class CstPage extends Component {
  constructor(props) {
    super(props);
    this.width = 0;
    this.brandCarousel = React.createRef();
    this.scrollViewIndex = 0;
    this.state = {
      widthScreen: Dimensions.get('window').width,
      centerWidth: Dimensions.get('window').width,
      brandCarouselIndex: 0,
      brandCarouselIncreaseIndex: true,
      cstData: null
    };

    this.nextBrandItem = this.nextBrandItem.bind(this);
  }

  translate(key) {
    return this.props.translateManager.translate(key);
  }

  nextBrandItem() {
    this.scrollView.scrollTo({ x: this.scrollViewIndex, y: 0, animated: true });
    this.scrollViewIndex += 3;
  }


  componentDidMount() {
    this.loadData();
    if (this.state?.cstData?.brands?.length >= 0)
      setInterval(this.nextBrandItem, 30);
  }

  loadData() {
    GetCstInfo(this.props.appCmCstId).then((result) => {
      this.setState({ cstData: result.Data });
    })
  }

  renderBrandCard(index, item) {
    return (
      <TouchableOpacity key={index} style={{ flex: 1, padding: 2 }}>
        <Image
          style={{ width: 150, height: 60 }}
          resizeMode='contain'
          source={{ uri: item.ImageName }}
        />
      </TouchableOpacity>
    );
  }

  render() {
    if (this.state.cstData == null) {
      return (<LoadingIndicator />);
    }

    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: this.state.widthScreen > 1000 ? .7 : 0, backgroundColor: '#e2e2e2' }} />

        <View style={{ flex: 1, marginVertical: 10 }}>
          <ScrollView onLayout={this._onLayout} style={{
            backgroundColor: '#ffffff', padding: '2vmin', paddingBottom: '1vmin', borderTopLeftRadius: 5, borderTopRightRadius: 5, height: '90%'
          }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: this.state.widthScreen > 1000 ? 20 : 0 }}>
              <CstLogo logo={this.state.cstData.Logo} name={this.state.cstData.Name} width='9vmin' height='9vmin' borderRadius='5vmin' />
              <View>
                <Text style={{ marginLeft: 10, fontFamily: Fonts.Bold, fontSize: '3vmin' }}>
                  {this.state.cstData.Name}
                </Text>
                <Text style={{ marginLeft: 10, fontSize: '1.5vmin', color: '#A0A0A0' }}>{this.state.cstData.Domaines.map(domaine => domaine.Name).join(' • ')}</Text>

              </View>
            </View>

            <View style={{ paddingTop: 20, marginHorizontal: this.state.widthScreen > 1000 ? 30 : 0 }} >
              <ViewMoreText
                numberOfLines={4}
                renderViewMore={(onPress) => <Text style={{ color: SecondaryColor, fontFamily: Fonts.Regular }} onPress={onPress}>{this.translate("ViewMore")}</Text>}
                renderViewLess={(onPress) => <Text style={{ color: SecondaryColor, fontFamily: Fonts.Regular }} onPress={onPress}>{this.translate("ViewLess")}</Text>}
                textStyle={{ fontFamily: Fonts.Regular, textAlign: 'center' }}
              >
                {this.state.cstData.Description}
              </ViewMoreText>
            </View>

            {/* <RatingIndicator mode='small' style={{ marginLeft: 10, alignItems: 'center' }} rating={{ Value: this.state.cstData.Rating, NbReviews: this.state.cstData.NbReviews }} /> */}

            <View style={{ marginVertical: 15, marginHorizontal: this.state.widthScreen > 1000 ? 20 : 0, borderColor: "#e2e2e2" }}>
              <Text style={{ fontFamily: Fonts.SemiBold, color: MainColor, fontSize: 16 }}>
                {this.translate("CstPage.LoyaltyGift")}
              </Text>
              <Text style={{ fontFamily: Fonts.Regular }}>
                {this.state.cstData.Cards[0].Modality}
              </Text>
            </View>

            <View style={{ marginHorizontal: this.state.widthScreen > 1000 ? 20 : 0, borderColor: "#e2e2e2" }} onLayout={(event) => this.screenWidth = event.nativeEvent.layout.width}>
              <Text style={{ fontFamily: Fonts.SemiBold, color: MainColor, fontSize: 16 }}>
                {this.translate("CstPage.Pos")}
              </Text>
              <View onLayout={(event) => this.setState({ centerWidth: event.nativeEvent.layout.width })} style={{ flex: 1, backgroundColor: '#fff' }}>
                <PosArrowScrollview vertical={this.state.widthScreen < 1000} showModalImages={this.props.showModalImages} data={this.state.cstData} width={this.state.centerWidth} />
              </View>
            </View>

            <View style={{ alignContent: 'center', paddingTop: 10, marginHorizontal: this.state.widthScreen > 1000 ? 20 : 0, visibility: this.state.cstData.Brands.length != 0 ? "visible" : "hidden" }}>
              <Text style={{ fontFamily: Fonts.SemiBold, color: MainColor }}>
                {this.translate("CstPage.Brands")}
              </Text>
              <ScrollView
                ref={(ref) => this.scrollView = ref}
                horizontal
                style={{ flex: 1 }}
              >
                {this.state.cstData.Brands.map((item, index) => {
                  return this.renderBrandCard(index, item)
                })}

              </ScrollView>
            </View>
          </ScrollView>
          <StoreFooter />

        </View>
        <View style={{ flex: this.state.widthScreen > 1000 ? .7 : 0, backgroundColor: '#e2e2e2' }} />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translateManager: state.translateManager
  }
}

export default connect(mapStateToProps)(CstPage);