import React, { Component } from 'react';
import { View, Text } from 'react-native';
import Svg, { Path, G } from 'react-native-svg';

class ic_post_promo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.size || 40}
        height={this.props.size || 40}
        viewBox="0 0 40 40"
      >
        <G fill={this.props.color || "#000"}>
          <Path d="M15.766 2.668c-1.399.633-3 2.297-3.266 3.434-.102.398-.2 1.765-.234 3.066l-.098 2.332-2.566.102-2.536.097-.234 3.735c-.133 2.066-.164 7.367-.098 11.8.098 7.899.133 8.098.868 8.832l.765.766h11.766c11.133 0 11.832-.031 12.5-.633.699-.633.699-.867.699-12.566V11.699l-2.566-.097-2.598-.102-.203-2.668c-.2-3-.531-4-1.664-5.031C24.867 2.5 23.934 2.234 20.5 2.168c-2.668-.066-3.7.031-4.734.5zm5.336 1.664c1.53 1.336 1.964 2.469 1.832 5.035l-.102 2.133H17.5l-.102-2.133c-.066-1.168.036-2.535.235-3.066.234-.633.199-1.035 0-1.168-.735-.434-1.133.633-1.301 3.469l-.164 2.898-1.402.102-1.434.097V9.332c0-2.664.469-3.797 2-5.133.867-.699 1.402-.867 2.836-.867 1.531 0 2 .168 2.934 1zm3.632-.434C26.266 4.801 27 6.566 27 9.332v2.367l-1.398-.097-1.434-.102L24 8.668c-.133-2.234-.332-3.066-.934-3.902-.398-.567-.734-1.133-.734-1.23 0-.403 1.434-.169 2.402.362zm3.598 19.836c0 6.098-.133 11.266-.266 11.465-.199.367-11.332.668-17.632.535-1.97-.066-2.036-.101-2.399-1.101-.402-1.168-.535-16.434-.168-19.934l.235-2.031h20.23zm4.27-.632c.03 5.632-.036 10.73-.204 11.332-.23.964-.398 1.066-1.664 1.168l-1.402.097V24.4c0-6.2.102-11.399.234-11.532.133-.133.832-.199 1.602-.133l1.332.098zm0 0" />
          <Path d="M13.035 20.465c-.902.969-1 2.637-.203 3.535 1.367 1.5 4.5.234 4.5-1.832 0-1.168-1.398-2.5-2.598-2.5-.566 0-1.234.297-1.699.797zm2.73.902c.5.7.403 1.832-.163 2.168-.168.098-.668.098-1.102 0-1.102-.27-1.5-1.8-.668-2.433.867-.602 1.402-.536 1.934.265zm0 0M20.133 21.066c-.567.801-2.098 2.899-3.434 4.735-1.465 2-2.332 3.465-2.199 3.8.168.434.266.399.867-.101.965-.898 6.399-8.566 6.434-9.133.066-.969-.7-.668-1.668.7zm0 0M19.832 25.832C18.168 27.465 19.168 30 21.5 30c2.398 0 3.465-2.867 1.633-4.3-1.168-.934-2.301-.868-3.301.132zm2.5.77c1.27 1.03.234 2.73-1.367 2.23-.899-.297-1.133-1.434-.43-2.2.7-.765.863-.8 1.797-.03zm0 0" />
        </G>
      </Svg>
    );
  }
}

export default ic_post_promo;
