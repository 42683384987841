import React, { Component } from 'react';
import { Dimensions, Text, View, FlatList } from 'react-native';
import ArticleItem from '../components/ArticleItem';
import CstLogo from '../components/CstLogo';
import LoadingIndicator from '../components/LoadingIndicator';
import { getArticleDetails, getArticleFromLink } from '../services/consumerApi';
import Error from './Error';
import { Fonts } from '../utils/utils';


class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      isLoading: true,
      currentImageIndex: 0,
      widthScreen: Dimensions.get('window').width
    };
  }

  componentDidMount() {
    var articles = [];
    if (!this.props.ids || !Array.isArray(this.props.ids) || this.props.ids.length == 0) {   
      if (!this.props.article)
        return;
      getArticleFromLink(this.props.article).then((response) => {
        articles.push(response.Data);
        this.setState({ articles, isLoading: false });
      })
        .catch((e) => {
          this.setState({ errorAPI: e })
        })
    }
    else {
      var ids = this.props.ids;
      let idx0 = ids.indexOf("0");
      if(idx0>=0)
       ids.splice(idx0,1);
      ids.map((articleId) => {
          getArticleDetails(articleId).then((data) => {
            articles.push(data.Data);
          }).then((r) => {
            if (articles.length == ids.length) {//all articles are loaded
              articles.sort((a, b) => (ids.indexOf(a.AppCmArticleId) - ids.indexOf(b.AppCmArticleId)));
              this.setState({ articles, isLoading: false });
            }
          }).catch((e) => {
            this.setState({ errorAPI: e })
          })
        })

      }    
  }

  componentDidUpdate() {
    let width = Dimensions.get('window').width;
    if (this.state.widthScreen != width)
      this.setState({ widthScreen: width })
  }


  _renderArticle(article) {
    return (<View style={{ height: '100%', flexDirection: 'row' }}>
      <View style={{ flex: this.state.widthScreen > 1000 ? .5 : 0, backgroundColor: '#e2e2e2' }} />
      <ArticleItem article={article} showModalImages={this.props.showModalImages} />
      <View style={{ flex: this.state.widthScreen > 1000 ? .5 : 0, backgroundColor: '#e2e2e2' }} />
    </View>
    )
  }

  _renderHeaderList = () => {
    return (
      <View style={{ flexDirection: 'row', marginBottom: 5 }}>
        <View style={{ flex: this.state.widthScreen > 1000 ? .5 : 0, backgroundColor: '#e2e2e2' }} />
        <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#fff', padding: '1vmin', marginHorizontal: 10 }}>

          <CstLogo logo={this.props.shopLogo || this.state.articles[0].ShopLogo} name={this.props.shopName || this.state.articles[0].ShopName} width='9vmin' height='9vmin' borderRadius='5vmin' />
          <Text style={{ textAlign: 'Left', fontSize: '3vmin', marginLeft: '1vmin', fontFamily:Fonts.Bold }}>
            {this.props.shopName || this.state.articles[0].ShopName}
          </Text>
        </View>

        <View style={{ flex: this.state.widthScreen > 1000 ? .5 : 0, backgroundColor: '#e2e2e2' }} />
      </View>
    )
  }

  render() {
    if ((!this.props.ids || !Array.isArray(this.props.ids) || this.props.ids == 0) && !this.props.article)
      return <Error />
    if (this.state.isLoading || this.state.articles.length == 0)
      return <LoadingIndicator />

    return (
      <FlatList
        style={{ flex: 1 }}
        keyExtractor={(item, index) => index.toString()}
        data={this.state.articles}
        renderItem={({ item }) => this._renderArticle(item)}
        ListHeaderComponent={this._renderHeaderList}
      />
    );
  }
}
export default Articles;
