import { faEnvelope, faGlobe, faMapPin, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import moment from 'moment';
import { Component } from 'react';
import { FlatList, Image, View, Text } from 'react-native';
import { Linking, TouchableOpacity } from 'react-native-web';
import { InvalidColor, MainColor, SecondaryColor } from '../utils/appColors';
import { connect } from 'react-redux';
import { Fonts } from '../utils/utils';

class PosInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderLargeOpeningTime: false
    };
  }

  translate(key) {
    return this.props.translateManager.translate(key);
  }

  getDayNameOfWeek() {
    return [
      this.translate("PosInfo.Monday"),
      this.translate("PosInfo.Thuesday"),
      this.translate("PosInfo.Wednesday"),
      this.translate("PosInfo.Thursday"),
      this.translate("PosInfo.Friday"),
      this.translate("PosInfo.Saturday"),
      this.translate("PosInfo.Sunday"),
    ];
  }

  renderPosOpeningTimeLarge(pos) {
    if (pos.OpeningTime == null || pos.OpeningTime.length == 0) return <View />;

    let currentDayIndex = moment().isoWeekday() - 1;

    return (
      <View style={{ flex: 1,paddingTop: this.props.vertical ? 10 : 0, alignSelf: this.props.vertical?'center':'flex-start'}}>

        {pos.OpeningTime.map((item, index) => {
          let isCurrentDay = index == currentDayIndex;

          return (
            <View style={{ flexDirection: "row", flex: 1,marginTop: this.props.vertical ? 10 : 0}} key={index}>
              <Text style={{ width: 80, fontFamily: isCurrentDay ? Fonts.SemiBold : Fonts.Regular }}>{this.getDayNameOfWeek()[index]}: </Text>
              {this.displayDayOpeningTime(isCurrentDay, item.OpenHours)}
            </View>
          )
        }
        )}
      </View>
    );
  }

  displayDayOpeningTime(isCurrentDay, openHours) {
    let currentHour = moment().hour();

    let fontFamily = isCurrentDay ? Fonts.SemiBold : Fonts.Regular;

    let isOpen = false;
    let color = 'red';

    if (openHours.length == 0)
      return <Text style={{ marginLeft: 10, fontFamily: fontFamily }}>{this.translate("PosInfo.Closed")}</Text>

    return openHours.map((hour, index) => {
      if (hour.OpenHour != undefined) {
        let openHour = this.timeFromString(hour.OpenHour);
        let closeHour = this.timeFromString(hour.CloseHour);

        if (!isOpen) {
          isOpen = currentHour >= openHour.hour() && currentHour <= closeHour.hour();
          color = isOpen ? '#23b026' : InvalidColor;
        }
        return (
          <View style={{ flexDirection: "row", marginLeft: 10, flex: 1 }} key={index}>
            <Text style={{ color: isCurrentDay ? color : 'black', fontFamily: fontFamily }}>{openHour.format("HH:mm")} - </Text>
            <Text style={{ fontFamily: fontFamily, color: isCurrentDay ? color : 'black' }}>
              {hour.CloseHour != undefined
                ? closeHour.format("HH:mm")
                : ""}
            </Text>
          </View>
        );
      }
    })
  }

  timeFromString(value) {
    return moment(value, "HH:mm:ss");
  }

  renderPhone() {
    return (
      <View style={{ flexDirection: 'row', marginTop:10}}>
        <FontAwesomeIcon size={15} icon={faPhone} />
        <Text style={{ paddingLeft: 10, fontFamily: Fonts.Regular }}>{this.props.posData.Tel}</Text>
      </View>
    )
  }

  renderEmail() {
    return (
      <View style={{ flexDirection: 'row', marginTop:10}}>
        <FontAwesomeIcon size={15} icon={faEnvelope} />
        <Text style={{ paddingLeft: 10, fontFamily: Fonts.Regular }}>{this.props.cstData.Email}</Text>
      </View>
    )
  }

  renderWebSite() {
    return (
      <View style={{ flexDirection: 'row', marginTop:10 }}>
        <FontAwesomeIcon size={15} icon={faGlobe} />
        <Text style={{ color: SecondaryColor, paddingLeft: 10, fontFamily: Fonts.Regular, flexWrap: 'wrap' }}
          onPress={() => Linking.openURL(this.props.cstData.Website)}>
          {this.props.cstData.Website}
        </Text>
      </View>
    )
  }

  renderContact() {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row'}}>
          <FontAwesomeIcon size={16} icon={faMapPin} />
          <TouchableOpacity style={{ flexDirection: 'column' }} onPress={() => Linking.openURL('https://www.google.com/maps/search/?api=1&query=' + `${this.props.posData.Street} ${this.props.posData.StreetNumber} ${this.props.posData.ZoneGeo.ZipCode} ${this.props.posData.ZoneGeo.Name}`)} >
            <Text style={{ paddingLeft: 10, color: SecondaryColor }}>{this.props.posData.Street} {this.props.posData.StreetNumber}, {this.props.posData.ZoneGeo.ZipCode} {this.props.posData.ZoneGeo.Name}</Text>
          </TouchableOpacity>
        </View>

        {this.props.posData.Tel ? this.renderPhone() : null}
        {this.props.cstData.Email ? this.renderEmail() : null}
        {this.props.cstData.Website ? this.renderWebSite() : null}


      </View>
    );
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.props.cstData.Pos && this.props.cstData.Pos.length > 1
          ? (
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontFamily: Fonts.SemiBold, fontSize: 16, color: MainColor }} >{this.props.posData.Name}</Text>
            </View>
          )
          : null}

        <View style={{ flexDirection: this.props.vertical ? 'column' : 'row', padding: 10, flex: 1 }}>
          {this.renderContact()}
          <View style={{ flex: 1}}>
            {this.renderPosOpeningTimeLarge(this.props.posData)}

          </View>
        </View>

        <View style={{ marginTop: 20, visibility: this.props.posData.AmbianceImages.length != 0 ? "visible" : "hidden", flex: 1 }}>
          <Text style={{ fontFamily: Fonts.SemiBold, fontSize: 16, color: MainColor, alignSelf: 'flex-start' }}>
            {this.translate("PosInfo.Atmosphere")}
          </Text>
          <FlatList
            style={{ marginTop: 10 }}
            data={this.props.posData.AmbianceImages}
            horizontal
            ItemSeparatorComponent={<View style={{ width: 5 }} />}
            renderItem={({ item, index }) => (
              <TouchableOpacity onPress={() => this.props.showModalImages(this.props.posData.AmbianceImages.map((item) => item.ImageName), index)}>
                <Image style={{ width: 150, height: 150 }} source={{ uri: item.ImageName }} />
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translateManager: state.translateManager
  }
}

export default connect(mapStateToProps)(PosInfo);
