import React, { Component } from 'react';
import { StyleSheet, View, Text  } from 'react-native';

class IconLetter extends Component {
  _getColor(letter){
    return ColorLetter[this._FormatLetter(letter)];
}

  _FormatLetter(letter){
    return letter.toUpperCase().replace("Ô","O");
}

  render() {
    return (
      <View style={[this.props.style,{justifyContent:'center',backgroundColor:this._getColor(this.props.label[0]), width:this.props.width || 60,
        height:this.props.height || 60,  borderRadius:this.props.borderRadius || 30}]}>
        <Text style={[styles.anonymousIcon]}>{this._FormatLetter(this.props.label[0])}</Text>
      </View>
    );
  }
}

var styles= StyleSheet.create({
    anonymousIcon:{
        textAlign:"center",
        color:'#ffffff',
        alignSelf:'center',
    }
})

const ColorLetter = {
    "A":"#C0392B",
    "B":"#9B59B6",
    "C":"#2980B9",
    "D":"#1ABC9C",
    "E":"#27AE60",
    "F":"#F1C40F",
    "G":"#E67E22",
    "H":"#95A5A6",
    "I":"#34495E",
    "J":"#E74C3C",
    "K":"#8E44AD",
    "L":"#3498DB",
    "M":"#16A085",
    "N":"#2ECC71",
    "O":"#F39C12",
    "P":"#D35400",
    "Q":"#7F8C8D",
    "R":"#2C3E50",
    "S":"#7B241C",
    "T":"#633974",
    "U":"#1A5276",
    "V":"#117864",
    "W":"#196F3D",
    "X":"#9A7D0A",
    "Y":"#935116",
    "Z":"#1B2631",
    "0":"#6d4c41",
    "1":"#6d4c41",
    "2":"#6d4c41",
    "3":"#6d4c41",
    "4":"#6d4c41",
    "5":"#6d4c41",
    "6":"#6d4c41",
    "7":"#6d4c41",
    "8":"#6d4c41",
    "9":"#6d4c41",
}

export default IconLetter;
