import React, { Component } from 'react'
import { ActivityIndicator, View } from 'react-native'

export class LoadingIndicator extends Component {
    render() {
        return (
            <View style={[this.props.style,{flex:1,alignItems: 'center', justifyContent: 'center'}]}>
              <ActivityIndicator size='large' color={this.props.color || '#b2b2b2'}/>
            </View>
          )
    }
}

export default LoadingIndicator
