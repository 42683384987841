import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import PostItem from './components/PostItem';
import Articles from './views/Articles';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import ModalImage from './components/ModalImage';
import Error from './views/Error';
import CstPage from './views/CstPage';
import { Provider } from 'react-redux';
import Store from './Store/configureStore';
import * as Font from 'expo-font';
import { Image } from 'react-native';


const Stack = createStackNavigator();

const linking = {
  config: {
    screens: {
      PostItem: "posts",
      Article: "articles",
      Error: "",
    }
  },
};

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalImages: undefined,
      indexImage: 0,
      loaded: false
    }
  }


  async loadFonts() {
    await Font.loadAsync({
      'Livvic-Light': require('./assets/fonts/Livvic-Light.ttf'),
      'Livvic-Regular': require('./assets/fonts/Livvic-Regular.ttf'),
      'Livvic-Medium': require('./assets/fonts/Livvic-Medium.ttf'),
      'Livvic-SemiBold': require('./assets/fonts/Livvic-SemiBold.ttf'),
      'Livvic-Bold': require('./assets/fonts/Livvic-Bold.ttf')
    })
    this.setState({ loaded: true })
  }

  componentDidMount() {
    this.loadFonts();
  }


  _showModalImages(images, index) {
    this.setState({ modalImages: images, indexImage: index });
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }
    return (
      <Provider store={Store}>
        <View style={{ flex: 1 }}>
          {/* Hack else top menu not fix */}
          <View style={{ height: 1 }} />

          <NavigationContainer linking={linking}>

            <Stack.Navigator screenOptions={{
              cardStyle: { backgroundColor: '#e2e2e2' },
              headerTitle: () => <Pressable onPress={() => window.open('https://app.fidely-box.be/src=6')}>
                <Image source={require('./assets/LoginLogo.png')} style={{ height: 150, aspectRatio: 1 }} resizeMode='contain' />
              </Pressable>,
              headerTitleAlign: 'center',
            }}>
              <Stack.Screen name="PostItem" children={(props) => <PostItem {...props.route.params} navigation={props.navigation} showModalImages={(image, index) => this._showModalImages(image, index)} />} />
              <Stack.Screen name="Article" children={(props) => <Articles {...props.route.params} showModalImages={(image, index) => this._showModalImages(image, index)} />} />
              <Stack.Screen name="CstPage" children={(props) => <CstPage {...props.route.params} showModalImages={(image, index) => this._showModalImages(image, index)} />} />
              <Stack.Screen name="Error" component={Error} />
            </Stack.Navigator>
          </NavigationContainer>
          {(this.state.modalImages)
            ? <ModalImage onClose={() => this.setState({ modalImages: undefined })} images={this.state.modalImages} index={this.state.indexImage} />
            : null}

        </View>
      </Provider >

    );
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    marginTop: 10
  },
});
