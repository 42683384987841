import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Component } from 'react';
import { View, Text, SafeAreaView, TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import PosInfo from './PosInfo';

export default class PosArrowScrollview extends Component {

  constructor(props) {
    super(props);
    this.itemIndex = 0;
    this.state = {
    }
  }

  componentDidUpdate() {
    this.scrollView.scrollTo({ x: (this.props.width - 60) * this.itemIndex, y: 0, animated: false });
  }

  onArrowPress(isNext) {
    if (isNext && this.itemIndex < this.props.data.Pos.length - 1) {
      this.itemIndex += 1;
      this.scrollView.scrollTo({ x: (this.props.width - 60) * this.itemIndex, y: 0, animated: true });
    }
    else if (!isNext && this.itemIndex > 0) {
      this.itemIndex -= 1;
      this.scrollView.scrollTo({ x: (this.props.width - 60) * this.itemIndex, y: 0, animated: true });
    }
  }

  _renderItem({ item, index }) {
    return (
      <View style={{
        backgroundColor: 'floralwhite',
        borderRadius: 5,
        height: 250,
        padding: 50,
        marginLeft: 25,
        marginRight: 25,
      }}>
        <Text style={{ fontSize: 30 }}>{item.title}</Text>
        <Text>{item.text}</Text>
      </View>

    )
  }

  renderCard(index, pos) {
    return (
      <View key={index} style={{ flex:1, width:this.props.width-(this.props.data?.Pos && this.props.data.Pos.length>1 ?60:0) }}>
        <PosInfo vertical={this.props.vertical} showModalImages={this.props.showModalImages} cstData={this.props.data} posData={pos} />
      </View>
    )
  }

  renderArrowLeft() {
    return (
      <View style={{ justifyContent: 'center' }}>
        <TouchableOpacity onPress={() => this.onArrowPress(false)}>
          <FontAwesomeIcon color='grey' size={30} icon={faChevronCircleLeft} />
        </TouchableOpacity>
      </View>
    );
  }

  renderArrowRight(){
    return (
      <View style={{ justifyContent: 'center' }}>
        <TouchableOpacity onPress={() => this.onArrowPress(true)}>
          <FontAwesomeIcon color='grey' size={30} icon={faChevronCircleRight} />
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, paddingVertical: 10 }}>
        <View style={{ flex: 1, flexDirection: 'row'}}>

          {this.props.data?.Pos && this.props.data.Pos.length>1 ? this.renderArrowLeft():null}

          <ScrollView
            ref={(ref) => this.scrollView = ref}
            horizontal
            showsHorizontalScrollIndicator={false}
            disableIntervalMomentum={true}
            decelerationRate={.9}
            scrollEnabled={false}
            style={{ flex: 1}}
          >
            {this.props.data.Pos.map((item, index) => this.renderCard(index, item))}
          </ScrollView>

          {this.props.data?.Pos && this.props.data.Pos.length>1? this.renderArrowRight():null}

        </View>
      </SafeAreaView>
    );
  }
}
