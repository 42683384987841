import TranslateManager from '../../Translations/TranslateManager'

const initialState = { user: undefined }

export const ACTION = {
}

function toggleUser(state = initialState, action) {
  let nextState
  switch (action.type) {
    default:
      nextState = {
        ...state,
        translateManager: new TranslateManager(1)
      }
      return nextState || state;  }
}

export default toggleUser