import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { Component } from 'react'
import { Text, View, Image, Dimensions, TouchableOpacity, StyleSheet } from 'react-native'
import { SecondaryColor } from '../utils/appColors';
import { getBadgeIconFromName, PostLayoutType } from '../utils/utils';


const maxImageDisplay = 4;
export class PostImageLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstImageSize:undefined,
      widthPercentFirstImage: 0,
      heightPercentFirstImage: 0
    }
    this._computeImageSize();
    this.widthScreen = this.props.width;
  }

  _computeImageSize() {
    const images = this.props.images;
    if (images != undefined && images.length > 0) {
      var nbItem = images.length;
      nbItem = nbItem > maxImageDisplay ? maxImageDisplay : nbItem;
      var sizeOfSecondaryImage = 100 - (100 / (nbItem - 1));
      Image.getSize(images[0].uri, (width, height) => {
        switch (this.props.layoutType) {
          case PostLayoutType.Horizontal:
            this._setLayoutHorizontal(nbItem);
            break;
          case PostLayoutType.Vertical:
            this._setLayoutVertical(nbItem);
            break;
          default:
            if (width > height)
              this._setLayoutHorizontal(nbItem);
            else
              this._setLayoutVertical(nbItem);
            break;
        }
      });
    }
  }

  _setLayoutHorizontal(nbItem) {
    if (nbItem > 2) {
      var sizeOfSecondaryImage = 100 - (100 / (nbItem - 1));
      this.setState({ widthPercentFirstImage: 100, heightPercentFirstImage: sizeOfSecondaryImage });
    }
    else
      this.setState({ widthPercentFirstImage: 100, heightPercentFirstImage: 100 / nbItem });
  }

  _setLayoutVertical(nbItem) {
    if (nbItem > 2) {
      var sizeOfSecondaryImage = 100 - (100 / (nbItem - 1));
      this.setState({ widthPercentFirstImage: sizeOfSecondaryImage, heightPercentFirstImage: 100 });
    }
    else
      this.setState({ widthPercentFirstImage: 100 / nbItem, heightPercentFirstImage: 100 });
  }

  _getHeightPercentImage(nbItem, index) {
    if (this.props.squareLayout)
      return nbItem == 2 ? 100 : 50;
    if (index > 0) {
      if (this.state.heightPercentFirstImage == 100) {
        return (100 / (nbItem - 1));
      }
      return 100 - this.state.heightPercentFirstImage;
    }
    else {
      return this.state.heightPercentFirstImage;
    }
  }

  _getWidthPercentImage(nbItem, index) {
    if (this.props.squareLayout)
      return 50;
    if (index > 0) {
      if (this.state.widthPercentFirstImage == 100) {
        return (100 / (nbItem - 1));
      }
      return 100 - this.state.widthPercentFirstImage;
    }
    else {
      return this.state.widthPercentFirstImage;
    }
  }

  onLayout(event, index) {
    if (index != 0)
      return;

    let firstImageSize = { width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height };
    this.setState({ firstImageSize });
  }

  _drawBadge(index) {
    if (!this.props.badge || index > 0 || !this.state.firstImageSize) return null;

    if (this.props.badge.Icon == null)
      this.props.badge.Icon = getBadgeIconFromName(this.props.badge.IconName);


    let imgHeight = this.state.firstImageSize.height;
    let imgWidth = this.state.firstImageSize.width;
    let minValue = imgHeight > imgWidth ? imgWidth : imgHeight;
    var badgeSize = this.props.badge.Size * minValue;

    return (
      <View style={{ position: 'absolute', left: this.props.badge.Left * imgWidth, top: this.props.badge.Top * imgHeight }}>
        <FontAwesomeIcon icon={this.props.badge.Icon} color={this.props.badge.Color} size={badgeSize} />
        <View style={{ position: 'absolute', justifyContent: 'center', width: badgeSize, height: badgeSize, backgroundColor: '#ffffff00' }}>
          <Text style={{
            textAlign: 'center',
            fontSize: this.props.badge.TextSize * minValue,
            color: this.props.badge.TextColor
          }}>
            {this.props.badge.Text}
          </Text>
        </View>
      </View>
    )
  }

  render() {
    var images = this.props.images;
    var nbItem = images.length;
    nbItem = nbItem > maxImageDisplay ? maxImageDisplay : nbItem;
    return (
      <View style={[styles.layoutImages, { flex: 1, flexDirection: (this.state.widthPercentFirstImage == 100) ? 'row' : 'column' }]}>
        {images.map((item, index) => {
          if (index > 3) return;
          return (
            <TouchableOpacity
              onPress={item.onPress}
              style={{
                height: this._getHeightPercentImage(nbItem, index) + '%',
                width: this._getWidthPercentImage(nbItem, index) + '%'
              }}
              activeOpacity={.8}
              key={index}
              onLayout={(event) => this.onLayout(event, index)}>

              <Image style={styles.imageItem} source={{ uri: item.uri }} resizeMethod='scale' />

              {(index == nbItem - 1 && images.length > maxImageDisplay)
                ?
                <View style={styles.extraImageViewOverlay}>
                  <Text style={styles.extraImageOverlay}>+{images.length - maxImageDisplay}</Text>
                </View>
                : null}

              {(item.description)
                ? <Text style={{ textAlign: 'center', marginBottom: 5, marginHorizontal: 1, marginTop: -1, backgroundColor: SecondaryColor, color: '#ffffff' }}>{item.description}</Text>
                : null}

              {this.props.badge != undefined ? this._drawBadge(index) : null}

            </TouchableOpacity>
          )
        })}

      </View>
    )
  }
}

const styles = StyleSheet.create({
  imageItem: {
    flex: 1, borderColor: '#f2f2f2',
    borderWidth: 1, margin: 3
  },
  layoutImages: {
    flexWrap: 'wrap',
  },
  extraImageViewOverlay: {
    position: 'absolute',
    backgroundColor: '#33333399',
    height: '100%',
    width: '100%',
    borderColor: '#ffffff',
    borderWidth: 1,
    justifyContent: 'center'
  },
  extraImageOverlay: {
    color: '#ffffff',
    fontSize: 28,
    textAlign: 'center',
    verticalAlign: 'center',
  }
});

export default PostImageLayout
