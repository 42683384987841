import React, { Component } from 'react';
import { View, Text, Image } from 'react-native';
import IconLetter from './IconLetter';

class CstLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
      if(this.props.logo){
          return <Image style={{width:this.props.width || 50, height:this.props.height || 50, borderRadius:this.props.borderRadius || 25, borderColor:'#a0a0a0', borderWidth:.5}} source={{ uri: this.props.logo}}/>
      }
    return (
            <IconLetter label={this.props.name} width={this.props.width || 50} height={this.props.height || 50} borderRadius={this.props.borderRadius || 25} style={this.props.textStyle}/>
    );
  }
}

export default CstLogo;
