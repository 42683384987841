import React, { Component } from 'react';
import { View, Text } from 'react-native';
import Svg, { Path, G } from 'react-native-svg';

class ic_post_ad extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.size || 40}
        height={this.props.size || 40}
        viewBox="0 0 40 40"
      >
        <G fill={this.props.color || "#000"}>
          <Path d="M30.531 4.402c-.398.434-.73.93-.73 1.16 0 .63-3.61 3.313-6.227 4.602l-2.515 1.262-7.45.164c-8.343.2-8.742.297-10.597 2.55-3.043 3.676-1.157 9.434 3.511 10.794l1.59.464.164 3.774c.168 4.074.465 4.8 2.22 5.96 1.093.727 3.015.829 3.874.2.465-.363.598-.96.629-3.246l.066-2.781 1.16-.098c1.524-.133 2.614-1.227 2.848-2.75.2-1.191.262-1.258 1.258-1.094 2.68.496 8.84 4.239 9.371 5.696.727 2.086 3.113 2.714 4.57 1.226.793-.762.825-.926.825-5.629 0-4.636.035-4.8.695-4.8.895 0 1.988-.598 2.684-1.458.664-.761.796-2.882.265-3.875-.398-.73-2.02-1.62-2.98-1.62-.63 0-.664-.169-.664-4.903 0-4.703-.032-4.934-.762-5.629-1.059-.992-2.879-.96-3.805.031zm2.715 1.094c.461.43.527 2.086.527 12.848 0 9.14-.097 12.418-.394 12.715-.563.562-1.824.496-2.055-.133-.097-.297-.199-5.992-.199-12.715 0-10.66.066-12.285.531-12.715.266-.297.63-.527.793-.527.168 0 .531.23.797.527zM29.801 18.41c0 5.363-.133 9.735-.297 9.735-.133 0-1.258-.66-2.45-1.457-2.12-1.422-5.331-2.848-6.456-2.848-1.325-.031-1.426-.363-1.325-5.63l.098-5.065 1.82-.461c2.286-.563 5.43-2.086 6.954-3.348.628-.563 1.257-.926 1.425-.824.133.062.23 4.535.23 9.898zm-12.25-.031v5.133h-5.43c-5.066 0-5.465-.035-6.492-.762-1.82-1.227-2.45-2.352-2.45-4.371 0-2.02.63-3.149 2.45-4.406 1.027-.696 1.426-.727 6.492-.727h5.43zm19.668-1.094c.297.399.531.926.531 1.16 0 .66-1.262 1.754-1.988 1.754-.598 0-.664-.23-.664-1.82 0-1.723.035-1.824.797-1.824.464 0 1.058.332 1.324.73zM13.574 29.602c0 3.777-.062 4.472-.527 4.636-.863.332-2.582-.86-3.016-2.117-.43-1.226-.594-6.36-.195-6.723.098-.132.992-.234 1.984-.234h1.754zm3.977-3.809c0 1.195-.399 1.691-1.324 1.691-1.028 0-1.293-.398-1.063-1.558.102-.63.367-.762 1.262-.762.89 0 1.125.133 1.125.629zm0 0" />
          <Path d="M7.121 14.637c-.101.265-.133 2.086-.101 4.105.066 2.945.199 3.61.597 3.61.395 0 .496-.665.496-3.942 0-2.879-.101-3.973-.398-4.074-.23-.098-.496.066-.594.3zm0 0M4.438 16.656c-.102.266-.133 1.192-.067 2.086.066 1.192.23 1.621.598 1.621.66 0 .726-3.84.097-4.039-.23-.097-.53.067-.628.332zm0 0" />
        </G>
      </Svg>
    );
  }
}

export default ic_post_ad;
