import { Component } from 'react';
import { View, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

class RatingIndicator extends Component {

  constructor(props) {
    super(props);

    this.nbStar = 5;//this.props.rating.Distribution.length;
    this.distributionMaxValue = 0;// Math.max(...this.props.rating.Distribution);
  }

  _renderStar(size) {
    return (
      <View style={{ flexDirection: 'row', marginHorizontal: 8 }}>
        {Array(this.nbStar).fill(0).map((item, index) => {
          let color = this.props.rating.Value >= index + 1 ? '#f7cf02' : '#e2e2e2'

          let colorHalf = this.props.rating.Value > index ? '#f7cf02' : '#e2e2e2'

          return (<View key={index} style={{ flexDirection: 'row' }}>
            <FontAwesomeIcon icon={faStar} color={color} size={size} />
            <FontAwesomeIcon icon={faStarHalf} color={colorHalf} size={size} style={{ position: 'absolute' }} />
          </View>)
        })}
      </View>
    )
  }

  _renderBar(index) {
    let ratio = this.props.rating.Distribution[index] / this.distributionMaxValue;
    let borderRadiusEnabled = (ratio == 1) ? { borderRadius: 4 } : { borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }
    let borderRadiusDisabled = (ratio == 0) ? { borderRadius: 4 } : { borderTopRightRadius: 4, borderBottomRightRadius: 4 }
    return (
      <View key={index} style={{ flexDirection: 'row' }}>
        <View style={[{ height: 7, flex: ratio, backgroundColor: '#f7cf02', marginVertical: 3 }, borderRadiusEnabled]}></View>
        <View style={[{ height: 7, flex: 1 - ratio, backgroundColor: '#e2e2e2', marginVertical: 3 }, borderRadiusDisabled]}></View>
      </View>

    )
  }

  _renderBarDistribution() {
    return (
      <View style={{ flex: 1, flexDirection: 'column-reverse', paddingHorizontal: 10 }}>
        {
          Array(this.nbStar).fill(0).map((item, index) => this._renderBar(index))}
      </View>
    )
  }

  render() {
    if (!this.props.mode || this.props.mode == 'small') {
      return (
        <View style={[this.props.style, { flexDirection: 'row', marginVertical: 2 }]}>
          <Text style={{ alignSelf: 'center' }}>{this.props.rating.Value || 0}</Text>
          {this._renderStar(15)}
          <Text style={{ alignSelf: 'center' }}>({this.props.rating.NbReviews || 0})</Text>
        </View>
      );
    }
    else {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View>
            <Text style={{ alignSelf: 'center', fontSize: 35 }}>{this.props.rating.Value || 0}</Text>
            {this._renderStar(15)}
            <Text style={{ alignSelf: 'center' }}>({this.props.rating.NbReviews || 0})</Text>
          </View>
          {this._renderBarDistribution()}
        </View>
      )
    }
  }
}

export default RatingIndicator;
