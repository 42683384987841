import React, { Component } from 'react';
import { View, Image, TouchableOpacity} from 'react-native';

class StoreFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <View style={{ flex: 1, flexDirection: 'row', backgroundColor: '#f2f2f2f2', alignItems: 'center', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, padding: 20 }}>
        <TouchableOpacity style={{flex:1}} onPress={()=>window.open('https://app.fidely-box.be?src=6')}>
          <Image source={require('../assets/google-play.png')} style={{ height: '8vmin', aspectRatio: 1 }} resizeMode='contain' resizeMethod='scale' />
        </TouchableOpacity>
        <TouchableOpacity  style={{flex:1}} onPress={()=>window.open('https://app.fidely-box.be?src=6')}>
          <Image source={require('../assets/apple-store.png')} style={{ height: '8vmin', aspectRatio: 1 }} resizeMode='contain' resizeMethod='scale' />
        </TouchableOpacity>
      </View>
    );
  }
}

export default StoreFooter;
