import Moment from 'moment'
import { getInfoDevice } from '../utils/utils';



// const URL_API = 'https://dev.api.athyla.be/V4.0/ShopFb/';
//const URL_API = 'http://stag.apiappcm.athyla.be/v2-0/ShopFb/';
const URL_API = 'https://wsappconso.athyla.be/v2-0/ShopFb/';

export function getPostDetails(link){
    return _callApi('GetPostFromLink', link);
}

export function getArticleFromLink(link){
  return _callApi('GetArticleFromLink', link);
}

export function getArticleDetails(articleId){
    return _callApi('GetArticleDetails', articleId);
}

export function GetCstInfo(appCmCstId){
    return _callApi('GetCstInfo', appCmCstId);
}

export function GetPOSInfo(appCmPosId){
    return _callApi('GetPosInfo', appCmPosId);
}

async function _callApi(action, param){
    var now = Moment().format('YYYY-MM-DD HH:mm:ss').toString();
    var url = URL_API+action; 
    return fetch(url,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'RequestDate': now,
            'Device':JSON.stringify(await getInfoDevice()),
        },
        body:JSON.stringify(param)
      })
    .then((response) => {  
        return response.json().then((json) => {
            if(json.ResultCode != 200){
                throw new Error(json.ResultCode.toString())
            }
            return json;
        })
    })
    .catch(reason=>{
        console.log(reason);
        if(reason.message === 'Network request failed')
            return {ResultCode:600};
        else
           throw new Error(reason);
    })
}